export const contentTableConfig = {
  title: '',
  // title: ('common.app-terminal-online-list'),
  propList: [
    {
      prop: 'extend1',
      label: 'monitor.app-name'
    },
    {
      prop: 'extend2',
      label: 'file.version-name'
    },
    {
      prop: 'successCount',
      label: 'monitor.success-count'
    },
    {
      prop: 'failedCount',
      label: 'monitor.failed-count'
    },
    {
      prop: 'pushedCount',
      label: 'monitor.pushed-count'
    },
    {
      prop: 'updatedBy',
      label: 'general.operator'
    },
    {
      prop: 'createdTime',
      label: 'general.operation-time',
      slotName: 'formatZeroTime'
    },
    {
      label: 'common.app-operation',
      width: '220',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true,
  showSelectColumn: false
}
